import { useMyOrganizations } from '@capturi/api-me'
import { AuthService } from '@capturi/auth'
import { useCurrentUser } from '@capturi/core'
import { usePageTitle, useScrollable } from '@capturi/react-utils'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, IconButton, Link, Text } from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { MdContentCopy, MdEdit, MdLock, MdOpenInBrowser } from 'react-icons/md'
import {
  LinkProps,
  Outlet,
  Link as RouterLink,
  useMatch,
  useParams,
  useResolvedPath,
} from 'react-router'
import { useCopyToClipboard } from 'react-use'
import CreateUpdateOrganization from '../shared/CreateUpdateOrganization'
import { useSingleOrganization } from '../shared/useOrganizations'
import { Title } from '../utils'
import AccessModal from './tabs/Access/AccessModal'

const Tab: React.FC<LinkProps> = ({ children, to, ...rest }) => {
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <Link
      display="flex"
      alignItems="center"
      textAlign="center"
      as={RouterLink}
      p="3"
      mb={0}
      pb={0}
      to={to}
      color={match ? 'primary.500' : 'gray.700'}
      {...rest}
    >
      {children}
    </Link>
  )
}

const OrganizationPage: React.FC = () => {
  const { uid } = useParams()

  if (!uid) return null
  // to render the component as a new one, we add a key to it
  return <OrganizationPageContent key={uid} uid={uid} />
}

const OrganizationPageContent: React.FC<{ uid: string }> = ({ uid }) => {
  const { data: organizations = [] } = useMyOrganizations()
  const { name, email = '', title = '' } = useCurrentUser()

  const [openGetAccessModal] = useModal(AccessModal)

  const isInCurrentOrg = useMemo(
    () => organizations.some((d) => d.id === uid),
    [organizations, uid],
  )
  const { data: org } = useSingleOrganization(uid)
  const [, copy] = useCopyToClipboard()
  const limitedAccess = org ? org.limitedAccess : true

  const ref = useRef(null)
  const height = useScrollable(ref)

  const [showCreateUpdateOrgModal] = useModal(CreateUpdateOrganization)

  usePageTitle(`Superpowers - ${org?.name}`)

  const handleEdit = (): void => {
    if (!org) return
    const {
      conversationLanguage,
      name,
      uiLanguage,
      limitedAccess,
      organizationType,
      uid,
    } = org
    showCreateUpdateOrgModal({
      conversationLanguage,
      name,
      uiLanguage,
      limitedAccess,
      organizationType,
      uid,
    })
  }

  const handleOrgChange = async (): Promise<void> => {
    if (isInCurrentOrg) await AuthService.changeOrganization(uid)
    else {
      openGetAccessModal({ email, name, organizationUid: uid, title })
    }
  }

  if (uid == null || org == null)
    return (
      <Flex h="80%" w="100%" justifyContent="center" alignItems="center">
        <Text fontSize="xl">Not found</Text>
      </Flex>
    )
  return (
    <>
      <Box w="100%">
        <Flex justifyContent="space-between">
          <div>
            <Flex alignItems="center">
              <IconButton
                aria-label="Get Access"
                alignSelf="flex-end"
                isDisabled={limitedAccess && !isInCurrentOrg}
                icon={limitedAccess ? <MdLock /> : <MdOpenInBrowser />}
                size="sm"
                onClick={handleOrgChange}
                variant="ghost"
                isRound
                color={isInCurrentOrg ? 'primary.500' : undefined}
              />
              <Title fontSize="3xl">{org.name}</Title>
              <IconButton
                aria-label="copy"
                alignSelf="flex-end"
                icon={<MdEdit />}
                size="sm"
                onClick={handleEdit}
                variant="ghost"
                isRound
              />
            </Flex>
          </div>
          <div>
            <Flex alignItems="center">
              <Text color="gray.500">{org.uid}</Text>
              <IconButton
                aria-label="copy"
                alignSelf="flex-end"
                icon={<MdContentCopy />}
                size="xs"
                onClick={() => {
                  copy(org.uid)
                }}
                variant="ghost"
                isRound
              />
            </Flex>
            <Text color="gray.500" textAlign="end" mr="2">
              <span title="Conversation Language">
                {org.conversationLanguage}
              </span>
              {' | '}
              <span title="UI Language">{org.uiLanguage}</span>
            </Text>
          </div>
        </Flex>

        <Flex
          borderBottom="solid 1px"
          borderBottomColor="gray.500"
          mb="5"
          flexWrap="wrap"
        >
          <Tab to="config">Config</Tab>
          <Tab to="custom-props">Custom Props</Tab>
          <Tab to="insights">Insights</Tab>
          <Tab to="conversations">Conversations</Tab>
          <Tab to="mappings">Import Mappings</Tab>
          <Tab to="users">Users</Tab>
          <Tab to="teams">Teams</Tab>
          <Tab to="wordcloud">Word Cloud</Tab>
          <Tab to="auth-settings">Auth Settings</Tab>
          <Tab to="label-editor">Label Editor</Tab>
          <Tab to="metabase-reports">Metabase reports</Tab>
          <Tab to="key-topics">Key topics</Tab>
          <Tab to="access">Access</Tab>
        </Flex>
        <Box ref={ref} height={`${height}px`} overflowY="auto">
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default OrganizationPage
