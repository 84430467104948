import {
  OrganizationMetadataSettings,
  OrganizationSettings,
} from '@capturi/api-organization'
import { systemAdminAPI } from '@capturi/api-systemadmin'
import request, { ResponseError, getErrorObject } from '@capturi/request'
import { Button, Description } from '@capturi/ui-components'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  StackDivider,
  Text,
  Textarea,
  chakra,
  useToast,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import useSWR from 'swr'

import { Examples } from './examples'

type CustomPropSettingsType =
  OrganizationMetadataSettings[keyof OrganizationMetadataSettings]

export const CustomProperties: React.FC = () => {
  const { uid: organizationUid = '' } = useParams()

  const toast = useToast()

  const { data, mutate } = useSWR<OrganizationSettings>(
    systemAdminAPI.getOrganizationSettings(organizationUid).url,
  )

  const handleSave = async (
    name: string,
    config: CustomPropSettingsType,
  ): Promise<void> => {
    try {
      const resp = await request<OrganizationSettings>(
        systemAdminAPI.upsertOrganizationSettings(organizationUid, {
          metadata: {
            [name]: config,
          },
        }),
      )
      mutate(resp)
      toast({
        status: 'success',
        title: `"${name}" updated`,
      })
    } catch (error) {
      toast({
        status: 'error',
        description: getErrorObject(error).message,
      })
    }
  }

  return (
    <Grid
      templateAreas={{
        base: `
          'guide'
          'divider'
          'config'
        `,
        lg: `
          'config divider guide'
        `,
      }}
      templateColumns={{
        lg: '2fr 1px 2fr',
      }}
      gap={4}
    >
      <Box gridArea="guide">
        <Examples />
      </Box>
      <StackDivider
        gridArea="divider"
        borderColor="gray.300"
        borderWidth="1px"
      />
      <Box gridArea="config">
        <Accordion allowMultiple allowToggle>
          {Object.entries(data?.metadata ?? {}).map(([name, config]) => (
            <AccordionItem key={name}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <CustomPropHeading name={name} config={config} />
                </Box>
                {config.enabled && (
                  <Badge colorScheme="green" mx={2}>
                    Enabled
                  </Badge>
                )}
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <SettingsJSONEditor
                  organizationUid={organizationUid}
                  name={name}
                  config={config}
                  onSave={(config) => {
                    handleSave(name, config)
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Grid>
  )
}

function CustomPropHeading({
  name,
  config,
}: {
  name: string
  config: CustomPropSettingsType
}): React.ReactElement {
  const label = config.label
    ? config.label['da-DK'] ?? Object.values(config.label)[0]
    : null
  if (label) {
    return (
      <>
        <Text fontWeight="medium">{name}</Text>
        <Description fontSize="md" fontWeight="normal">
          {label}
        </Description>
      </>
    )
  }
  return <>{name}</>
}

type CustomPropsBase = {
  config: CustomPropSettingsType
  name: string
  organizationUid: string
  onSave: (config: CustomPropSettingsType) => void
}

function SettingsJSONEditor(props: CustomPropsBase): React.ReactElement {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const { config, name, onSave, organizationUid } = props
  const isNumberProp = name.startsWith('customNumberProp')
  const [value, setValue] = useState(() => {
    return JSON.stringify(props.config, null, 2)
  })
  const [title, setTitle] = useState(
    config.label ? config.label['da-DK'] ?? Object.values(config.label)[0] : '',
  )

  const handleAutoFill = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const result = await request.get<string>(
        `superpowers/organization/${organizationUid}/custom-prop/${capitalizeFirstLetter(
          name,
        )}?label=${title}&daysToLookBack=180`,
      )

      setValue(JSON.stringify(result, undefined, '  '))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (error instanceof ResponseError) {
        toast({
          title: error.name,
          description: error.message,
          status: 'warning',
        })
      } else {
        toast({ title: 'Unknown error', status: 'warning' })
      }
    }
  }

  const handleSave = (): void => {
    try {
      const data = JSON.parse(value)
      onSave(data)
    } catch (e) {
      if (e instanceof SyntaxError) {
        toast({
          status: 'error',
          title: e.message,
          description: <chakra.pre fontSize="sm">{e.stack}</chakra.pre>,
          isClosable: true,
        })
      }
    }
  }

  return (
    <Box>
      {!isNumberProp && (
        <Flex alignItems="center">
          <InputGroup mb="2" size="sm">
            <InputLeftAddon>Label:</InputLeftAddon>
            <Input
              onChange={(e) => setTitle(e.currentTarget.value)}
              value={title}
              isDisabled={isLoading}
            />
            <Button
              ml="2"
              colorScheme="teal"
              onClick={handleAutoFill}
              isLoading={isLoading}
            >
              AutoFill
            </Button>
          </InputGroup>
        </Flex>
      )}
      <Textarea
        isDisabled={isLoading}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fontFamily="mono"
        minH="20em"
      />
      <Flex justify="flex-end" mt={2} onClick={handleSave}>
        <Button primary isDisabled={isLoading}>
          Save
        </Button>
      </Flex>
    </Box>
  )
}

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
