import { Box, BoxProps, ChakraComponent, Flex, Text } from '@chakra-ui/react'
import React, { Fragment, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router'

export const SmartRow = (
  key: string,
  value: unknown,
  props: BoxProps = {},
): ReactElement => {
  let formattedValue: string | number = ''

  if (value instanceof Date) {
    formattedValue = value.toLocaleString()
  } else if (typeof value === 'boolean') {
    formattedValue = value ? 'true' : 'false'
  } else if (typeof value === 'string' || typeof value === 'number') {
    formattedValue = value
  } else if (Array.isArray(value)) {
    formattedValue = value.join(', ')
  } else if (value && typeof value === 'object' && !Array.isArray(value)) {
    return (
      <Fragment key={key}>
        {Object.entries(value).map(([key, val]) => SmartRow(key, val, props))}
      </Fragment>
    )
  }
  return Row(key, formattedValue, props)
}

export const LinkRow = (
  key: string,
  value: string,
  path: string,
): ReactElement =>
  Row(
    key,
    <Link style={{ color: 'blue' }} to={`/superpowers/${path}`}>
      {value}
    </Link>,
  )

export const Row = (
  key: string,
  value: ReactNode,
  props: BoxProps = {},
): ReactElement => {
  return (
    <Box as="tr" key={key} {...props}>
      <Box textAlign="right" as="td" color="gray" pr="2">
        {key}
      </Box>
      <Box as="td" fontWeight="bold">
        {value}
      </Box>
    </Box>
  )
}

export const TableCard: ChakraComponent<
  'div',
  { title?: string; button?: ReactElement }
> = ({ children, title, ...rest }) => (
  <Card title={title} {...rest}>
    <table>
      <tbody>{children}</tbody>
    </table>
  </Card>
)

export const Card: React.FC<
  BoxProps & { title?: string; button?: ReactElement }
> = ({ children, title, button, ...rest }) => (
  <Box
    borderColor="#ededed"
    borderWidth="1px"
    borderRadius="10px"
    p="4"
    m="2"
    {...rest}
  >
    <Flex alignItems="center" justifyContent="space-between">
      {title && <Text fontSize="xl">{title}</Text>}
      {button}
    </Flex>
    {children}
  </Box>
)
