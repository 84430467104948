import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { Organization } from './types'

type OrganizationPayload = Omit<Organization, 'uid' | 'createdBy'> & {
  uid?: string
}

const selectorSingleOrg =
  (
    organizationUid: string | undefined,
  ): ((data: Organization[]) => Organization | undefined) =>
  (data) => {
    return organizationUid !== undefined
      ? data.find((o) => o.uid === organizationUid)
      : undefined
  }

export const useSingleOrganization = (
  organizationUid: string | undefined,
): UseQueryResult<Organization | undefined, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'organisation'],
    queryFn: () =>
      request.get<Organization[]>('authentication/superpowers/organization'),
    select: selectorSingleOrg(organizationUid),
  })
export const useOrganizations = (): UseQueryResult<
  Organization[],
  ResponseError
> =>
  useQuery({
    queryKey: ['superpowers', 'organisation'],
    queryFn: () => request.get('authentication/superpowers/organization'),
  })

export const useUpdateOrganization = (): UseMutationResult<
  Omit<Organization, 'uid' | 'createdBy'>,
  ResponseError,
  { uid: string; payload: Partial<OrganizationPayload> }
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ uid, payload }) => {
      return request.patch<Organization>(
        `authentication/superpowers/organization/${uid}`,
        {
          json: payload,
        },
      )
    },
    onSuccess: (data, { uid }) => {
      queryClient.setQueryData<Organization[]>(
        ['superpowers', 'organisation'],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((o) => (o.uid === uid ? data : o))
        },
      )
    },
  })
}
export const useCreateOrganization = (): UseMutationResult<
  Organization,
  ResponseError,
  OrganizationPayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload) => {
      return request.post<Organization>(
        'authentication/superpowers/organization',
        {
          json: payload,
        },
      )
    },
    onSuccess: (data) => {
      queryClient.setQueryData<Organization[]>(
        ['superpowers', 'organisation'],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return [...oldData, data]
        },
      )
    },
  })
}
