import { Emoji, Spinner } from '@capturi/ui-components'
import { Box, Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'
import {
  type Masterdata as MasterdataResult,
  useMasterdata,
} from '../shared/useMasterdata'
import { CreateCard } from './CreateCard'
import { DeleteCard } from './DeleteCard'

const groupByType = (data: MasterdataResult[]) =>
  Object.groupBy(data, ({ isDeleted, isProvisioned }) => {
    if (isProvisioned) return 'created'
    if (isDeleted) return 'markForDeleted'
    return 'toCreate'
  })

export const MasterdataProvision: FC = () => {
  const { data = {}, isLoading, error } = useMasterdata({ select: groupByType })

  if (error) {
    return (
      <Box>
        <Text fontSize="2xl" color="red.900">
          Error
        </Text>
        <Text>{error.message}</Text>
      </Box>
    )
  }

  return (
    <Box>
      <Text fontSize="2xl" color="green.900">
        Organizations to create
      </Text>

      {!isLoading && !data.toCreate?.length && (
        <Flex justifyContent="center">
          <Flex alignItems="center" direction="column">
            <Emoji fontSize="70px" symbol="🙉" />
            <Text>Nothing to do here</Text>
          </Flex>
        </Flex>
      )}

      {isLoading && (
        <Flex justifyContent="center">
          <Spinner size="xl" />
        </Flex>
      )}
      {data.toCreate?.map((p) => (
        <CreateCard provision={p} key={p.id} />
      ))}

      <Text fontSize="2xl" mt="12" color="red.900">
        Organizations to delete
      </Text>
      {!isLoading && !data.markForDeleted?.length && (
        <Flex justifyContent="center">
          <Flex alignItems="center" direction="column">
            <Emoji fontSize="70px" symbol="🙈" />
            <Text>Nothing to see here</Text>
          </Flex>
        </Flex>
      )}
      {isLoading && (
        <Flex justifyContent="center">
          <Spinner size="xl" />
        </Flex>
      )}
      {data.markForDeleted?.map((p) => (
        <DeleteCard provision={p} key={p.id} />
      ))}
    </Box>
  )
}
