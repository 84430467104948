import request from '@capturi/request/src/request'
import { Button, Spinner } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Flex, IconButton, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { MdRefresh } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router'

import ConfirmDeleteTranscription from './ConfirmDeleteTranscription'

const ViewTranscription: React.FC = () => {
  const { resultUid, spokenLanguage } = useParams()
  const [open] = useModal(ConfirmDeleteTranscription)
  const navigate = useNavigate()

  const { data, error, isLoading, refetch, isFetching } = useQuery<
    { result: string; status: 'inProgress' | 'done' },
    Error
  >({
    queryFn: () =>
      request.get(
        `superpowers/asr/transcription/custom/${resultUid}/${spokenLanguage}`,
      ),
    queryKey: ['customTranscription', resultUid, spokenLanguage],
    enabled: !!resultUid,
    refetchInterval: (query) => {
      if (query.state.data?.status === 'done') return false

      return 10_000
    },
  })

  return (
    <div>
      <Flex justifyContent="space-between">
        <Flex>
          <IconButton
            aria-label="refresh"
            title="refresh"
            variant="ghost"
            icon={<MdRefresh />}
            onClick={() => refetch()}
            isLoading={isFetching}
          />
          <Text mb="4" fontSize="2xl">
            {resultUid}
          </Text>
        </Flex>
        <Button
          colorScheme="red"
          onClick={() =>
            open({
              resultUid: resultUid || '',
              spokenLanguage: spokenLanguage || '',
              onSuccess: () => navigate('/superpowers/create-transcription'),
            })
          }
        >
          Delete
        </Button>
      </Flex>
      {error && (
        <Flex justifyContent="center" mt="20%" color="red.500">
          {error.message}
        </Flex>
      )}
      {(isLoading || data?.status === 'inProgress') && (
        <Flex justifyContent="center" mt="20%">
          <Spinner delay={0} />
        </Flex>
      )}
      {data && data.status === 'done' && (
        <Text whiteSpace="break-spaces">{data.result}</Text>
      )}
    </div>
  )
}

export default ViewTranscription
