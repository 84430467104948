import { Button, ChipsInput } from '@capturi/ui-components'
import { Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { MdPhone } from 'react-icons/md'
import {
  Customer,
  UseUpdateGlobalRepeatCallsblacklist,
} from './useRepeatCallsBlacklist'

type Props = { data: Customer[] }

const RepeatCallsBlacklist: FC<Props> = ({ data }) => {
  const { mutate: updateGlobalBlacklist } =
    UseUpdateGlobalRepeatCallsblacklist()
  const toast = useToast()
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    data.map((d) => d.customer),
  )

  const handleValuesAdded = (newValues: string[]): void => {
    const n = new Set([...phoneNumbers, ...newValues])
    setPhoneNumbers([...n])
  }

  const handleValueRemoved = (_value: string, index: number): void => {
    setPhoneNumbers((s) => s.toSpliced(index, 1))
  }

  const emptyPhrasesField = (): void => {
    setPhoneNumbers([])
  }

  const handleUpdateBlacklist = (): void => {
    updateGlobalBlacklist(phoneNumbers, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: 'Successfully updated global repeat call blacklist',
        })
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: 'Failed to update repeat call blacklist',
          description: error.message,
        })
      },
    })
  }

  return (
    <Flex flexDir="column" w="100%">
      <Flex align="center">
        <Icon as={MdPhone} mr={2} />
        <Text fontWeight="medium" fontSize="md">
          Remove these phone numbers from repeat calls
        </Text>
      </Flex>
      <Text color="gray.600" fontSize="sm" mb={2}>
        These phone numbers will not be included in the statistics and will not
        register repeat calls.
      </Text>
      <ChipsInput
        value={phoneNumbers}
        onValuesAdded={handleValuesAdded}
        onValueRemoved={handleValueRemoved}
        onClearValue={emptyPhrasesField}
        allowUpperCase={true}
        inputPlaceholderText={'Add phone number'}
      />

      <Button primary ml="auto" mt={2} onClick={() => handleUpdateBlacklist()}>
        Save
      </Button>
    </Flex>
  )
}

export default RepeatCallsBlacklist
