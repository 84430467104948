import { Spinner } from '@chakra-ui/react'
import { FC } from 'react'
import RepeatCallsBlacklist from './RepeatCallsBlacklist'
import { useGlobalRepeatCallsBlacklist } from './useRepeatCallsBlacklist'

const RepeatCallBlacklistWrapper: FC = () => {
  const { data } = useGlobalRepeatCallsBlacklist()
  if (!data) {
    return <Spinner />
  }
  return <RepeatCallsBlacklist data={data} />
}

export default RepeatCallBlacklistWrapper
