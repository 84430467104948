import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { useToast } from '@chakra-ui/react'
import { type FC } from 'react'
import { useMarkAsProvisioned } from '../shared/useMasterdata'

type Props = {
  type: 'deleted' | 'created'
  organizationName: string
  provisionId: string
} & BaseModalComponentProps

export const ConfirmMarkAsProvisionedModal: FC<Props> = ({
  onClose,
  organizationName,
  type,
  provisionId,
}) => {
  const toast = useToast()

  const { mutate, isPending } = useMarkAsProvisioned()

  const markAsProvisioned = () => {
    mutate(provisionId, {
      onSuccess: () => {
        toast({
          title: `Organization marked as ${type}}`,
          status: 'success',
        })

        onClose()
      },
      onError: (err) => {
        toast({
          title: `Could not mark organization as ${type}`,
          description: err.message,
          status: 'error',
        })
      },
    })
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            Mark {organizationName} As {type}
          </ModalHeader>

          <ModalCloseButton isDisabled={isPending} />
          <ModalBody>
            Mark {organizationName} As {type}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button mr="4" isDisabled={isPending} onClick={onClose}>
              Cancel
            </Button>
            <Button primary onClick={markAsProvisioned} isLoading={isPending}>
              Mark as {type}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
