import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import type { IntegrationConfigSchema } from './IntegrationConfigSchemaType'

type AudioImporterConfig = {
  integrationOptions: Record<string, string | string[] | boolean>
  customFields: Record<string, string>
  organizationUid: string
  integrationKey: string
  integrationType: string
  continuationToken: Date | string
  active: boolean
}
export const useAudioImporterConfig = (
  integrationType: string,
  integrationKey: string,
): UseQueryResult<AudioImporterConfig, ResponseError> =>
  useQuery({
    queryKey: ['importers', 'audio', integrationType, integrationKey],
    queryFn: () =>
      request.get(`importer/${integrationType}/superpowers/${integrationKey}`),
  })
export const useAudioImporterSchema = (
  integrationType: string,
  integrationKey: string,
): UseQueryResult<IntegrationConfigSchema, ResponseError> =>
  useQuery({
    queryKey: ['importers', 'audio', 'schema', integrationType, integrationKey],
    queryFn: () =>
      request.get(`importer/${integrationType}/${integrationKey}/schema`),
  })

export type PatchAudioImporterConfig = {
  customFields: Record<string, unknown>
  integrationOptions?: Record<string, unknown>
  continuationToken?: string | Date
}

export const useAddAudioImporter = (
  integrationType: string,
): UseMutationResult<
  AudioImporterConfig,
  ResponseError,
  {
    organizationUid: string
    organizationName: string
    integrationAuth: unknown
  },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload) =>
      request.post(`importer/${integrationType}/superpowers/add`, {
        json: { ...payload, integrationOptions: {}, customFields: {} },
      }),
    onSuccess: (importer) => {
      queryClient.setQueryData<AudioImporterConfig>(
        ['importers', 'audio', integrationType, importer.integrationKey],
        () => importer,
      )
    },
  })
}
export const useUpdateAudioImporter = (
  integrationType: string,
  integrationKey: string,
): UseMutationResult<
  { integrationKey: string },
  ResponseError,
  {
    customFields: Record<string, unknown>
    integrationOptions?: Record<string, unknown>
    continuationToken?: string | Date
  },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['importers', 'audio', integrationType, integrationKey],
    mutationFn: (payload) =>
      request.patch(
        `importer/${integrationType}/superpowers/${integrationKey}`,
        {
          json: payload,
        },
      ),
    onSuccess: (importer) => {
      queryClient.setQueryData<{ integrationKey: string }>(
        ['importers', 'audio', integrationType, integrationKey],
        (oldData) => {
          if (!oldData) {
            return importer
          }
          return { ...oldData, ...importer }
        },
      )
    },
  })
}

export const useToggleAudioImporter = (): UseMutationResult<
  { integrationType: string; integrationKey: string },
  ResponseError,
  { integrationType: string; integrationKey: string; action: 'start' | 'stop' },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ integrationKey, integrationType, action }) =>
      request.post(
        `importer/${integrationType}/superpowers/${integrationKey}/${action}`,
      ),
    onSuccess: (_, { integrationType, integrationKey, action }) => {
      queryClient.setQueryData<AudioImporterConfig>(
        ['importers', 'audio', integrationType, integrationKey],
        (oldData) => {
          if (!oldData) {
            return oldData
          }
          return { ...oldData, active: action === 'start' }
        },
      )
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'integrations'],
      })
    },
  })
}
