import { Button } from '@capturi/ui-components'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useMemo, useState, type ReactElement } from 'react'
import { Link } from 'react-router'
import Loader from '../Loader'
import { useOrganizations } from '../shared/useOrganizations'
import { emptyOrgSettings } from './emptyOrgSettings'
import { useSettingsOverview } from './useSettingsOverview'

const fields = [
  { key: 'all', name: 'All' },
  { key: 'aiEnabled', name: 'AI enabled' },
  { key: 'aiDisabled', name: 'AI disabled' },
  {
    key: 'noConversationSoftTimeToLive',
    name: 'No conversation soft time to live',
  },
  {
    key: 'noConversationHardTimeToLive',
    name: 'No conversation hard time to live',
  },
] as const

type FilterKey = (typeof fields)[number]['key']

const OrganizationSettingsOverview: React.FC = () => {
  const { data, isLoading, error } = useSettingsOverview()
  const [filter, setFilter] = useState<FilterKey>('all')
  const {
    data: organizations,
    isLoading: orgIsLoading,
    error: orgError,
  } = useOrganizations()

  const sortedOrgs = useMemo(() => {
    const compare = Intl.Collator('da-DK').compare
    if (!(organizations && data)) return []

    const orgSettings = new Map(
      data.organizations.map((o) => [o.organizationUid, o]),
    )

    return organizations
      .map((o) => {
        return {
          name: o.name,
          organizationUid: o.uid,
          ...(orgSettings.get(o.uid) || emptyOrgSettings),
        }
      })
      .sort((a, b) => compare(a.name, b.name))
  }, [organizations, data])

  const toShow = useMemo(() => {
    if (!sortedOrgs.length) return []

    switch (filter) {
      case 'aiEnabled':
        return sortedOrgs.filter((o) => o.ai.enabled)
      case 'aiDisabled':
        return sortedOrgs.filter((o) => !o.ai.enabled)
      case 'noConversationHardTimeToLive':
        return sortedOrgs.filter(
          (o) => o.deletionStrategy.conversationHardTimeToLive === null,
        )
      case 'noConversationSoftTimeToLive':
        return sortedOrgs.filter(
          (o) => o.deletionStrategy.conversationSoftTimeToLive === null,
        )

      default:
        return sortedOrgs
    }
  }, [sortedOrgs, filter])

  const renderButton = ({
    key,
    name,
  }: { key: FilterKey; name: string }): ReactElement => {
    return (
      <Button
        key={key}
        borderRadius={0}
        onClick={() => setFilter(key)}
        primary={filter === key}
      >
        {name}
      </Button>
    )
  }

  if (error) return <Text>{error.message}</Text>
  if (orgError) return <Text>{orgError.message}</Text>

  if (isLoading || orgIsLoading) return <Loader />

  return (
    <Box pb="50px">
      <Flex alignItems="center" mb="8">
        <Flex
          fontWeight="bold"
          height={8}
          bg="gray.200"
          alignItems="center"
          px="4"
        >
          {toShow.length}/{organizations?.length}
        </Flex>
        <Flex>{fields.map(renderButton)}</Flex>
      </Flex>
      <>
        {toShow.map((o) => (
          <div key={o.organizationUid}>
            <Link to={`/superpowers/organization/${o.organizationUid}`}>
              {o.name}
            </Link>
          </div>
        ))}
      </>
    </Box>
  )
}

export default OrganizationSettingsOverview
