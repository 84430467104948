import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useParams } from 'react-router'

import AgentsPerDay from './AgentsPerDay'
import AgentsPerMonth from './AgentsPerMonth'
import AgentsPerWeek from './AgentsPerWeek'
import ConversationsOverview from './ConversationsOverview'
import ConversationsPerDay from './ConversationsPerDay'
import ConversationsPerDayV2 from './ConversationsPerDayV2'
import ConversationsPerHour from './ConversationsPerHour'

const OrganizationInsights: React.FC = () => {
  const { uid: organizationUid } = useParams()
  const [showLegacy, setShowLegacy] = useState(false)
  if (!organizationUid) return null

  return (
    <div>
      <FormControl display="flex" alignItems="center" justifyContent="flex-end">
        <Switch
          id="legacy"
          onChange={(e) => setShowLegacy(e.currentTarget.checked)}
          isChecked={showLegacy}
        />
        <FormLabel htmlFor="only-succeeded" mb="0" ml="2">
          Legacy Mode
        </FormLabel>
      </FormControl>
      {showLegacy ? (
        <>
          <ConversationsOverview organizationUid={organizationUid} />
          <AgentsPerMonth organizationUid={organizationUid} />
          <AgentsPerWeek organizationUid={organizationUid} />
          <AgentsPerDay organizationUid={organizationUid} />
          <ConversationsPerDay organizationUid={organizationUid} />
        </>
      ) : (
        <>
          <ConversationsPerDayV2 organizationUid={organizationUid} />
          <ConversationsPerHour organizationUid={organizationUid} />
        </>
      )}
    </div>
  )
}

export default OrganizationInsights
