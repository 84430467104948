import { useOrganization } from '@capturi/stores'
import { Button } from '@capturi/ui-components'
import {
  Box,
  Card,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  List,
  Switch,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import isEqual from 'react-fast-compare'
import { MdAdd, MdClose } from 'react-icons/md'
import {
  KeyTopicDiscovery,
  useDeleteKeyTopicDiscovery,
  useKeyTopicsDiscoveryUpdate,
} from './useKeyTopicsDiscovery'

type Props = {
  props: KeyTopicDiscovery
  setActiveDiscovery: (uid: string) => void
  activeDiscovery: string | undefined
}

const KeyTopicDiscoveryForm: FC<Props> = ({
  props,
  setActiveDiscovery,
  activeDiscovery,
}) => {
  const organizationUid = useOrganization().uid
  const [state, setState] = useState<KeyTopicDiscovery>(props)
  const toast = useToast()
  const { mutate: updateKeyTopicsDiscovery, isPending: isUpdatePending } =
    useKeyTopicsDiscoveryUpdate()
  const { mutate: deleteKeyTopicsDiscovery, isPending: isDeletePending } =
    useDeleteKeyTopicDiscovery(organizationUid)

  const hasChanges = !isEqual(props, {
    ...state,
    isProd: activeDiscovery === state.discoveryUid,
  })

  const handleUpdateKeyTopicsDiscovery = (state: KeyTopicDiscovery): void => {
    updateKeyTopicsDiscovery(
      { ...state, isProd: activeDiscovery === state.discoveryUid },
      {
        onSuccess: () => {
          toast({ status: 'success', title: 'Successfully updated discovery' })
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: 'Failed to update discovery. Try again.',
            description: error.message,
          })
        },
      },
    )
  }

  const handleDeleteKeyTopicDiscovery = (): void => {
    deleteKeyTopicsDiscovery(state.discoveryUid, {
      onSuccess: () => {
        toast({
          title: 'Successfully deleted key topic discovery',
          status: 'success',
        })
      },
      onError: (error) => {
        toast({
          title: 'Error deleting key topic discovery. Try again.',
          status: 'error',
          description: error.message,
        })
      },
    })
  }

  const handleAddNewRelevantCategory = (newCategory: string) => {
    setState({
      ...state,
      mainCategories: [...state.mainCategories, newCategory],
    })
  }

  const handleAddNewIrrelevantCategory = (newCategory: string) => {
    setState({
      ...state,
      irrelevantDimensions: [...state.irrelevantDimensions, newCategory],
    })
  }

  const updateCategory = (index: number, newValue: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        mainCategories: prevState.mainCategories.map((item, i) =>
          i === index ? newValue : item,
        ),
      }
    })
  }

  const updateIrrelevantCategory = (index: number, newValue: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        irrelevantDimensions: prevState.irrelevantDimensions.map((item, i) =>
          i === index ? newValue : item,
        ),
      }
    })
  }

  const removeRelevantCategory = (index: number): void => {
    setState({
      ...state,
      mainCategories: state.mainCategories.toSpliced(index, 1),
    })
  }

  const removeIrrelevantCategory = (index: number): void => {
    setState({
      ...state,
      irrelevantDimensions: state.irrelevantDimensions.toSpliced(index, 1),
    })
  }

  return (
    <VStack align="flex-start">
      <Flex align="center">
        <Switch
          size="md"
          color="primary"
          isChecked={activeDiscovery === state.discoveryUid}
          onChange={() => {
            setActiveDiscovery(state.discoveryUid)
          }}
          isDisabled={isUpdatePending}
        />
        <Text ml={2} fontSize="16px">
          {activeDiscovery === state.discoveryUid
            ? 'Active (in production)'
            : 'Inactive'}
        </Text>
      </Flex>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <Input
          value={state.organizationDescription}
          onChange={(e) =>
            setState({ ...state, organizationDescription: e.target.value })
          }
        />
      </FormControl>
      <VStack align="flex-start" w="100%">
        <Card p={3} w="100%" mt={4}>
          <FormLabel>Relevant categories</FormLabel>
          <List>
            <Box borderLeft="3px solid" borderColor="success" pl={4}>
              {state.mainCategories.map((value, index) => (
                <Flex key={index} mb={2} alignItems="center">
                  <Textarea
                    minH="36px"
                    lineHeight="1.5"
                    mb={0}
                    value={value}
                    onChange={(e) => updateCategory(index, e.target.value)}
                  />
                  <IconButton
                    aria-label="remove "
                    size="sm"
                    variant="ghost"
                    _hover={{ backgroundColor: 'none' }}
                    _focus={{ backgroundColor: 'none' }}
                    onClick={() => removeRelevantCategory(index)}
                    icon={<MdClose />}
                  />
                </Flex>
              ))}
            </Box>
            <Flex justify="center">
              <Button primary onClick={() => handleAddNewRelevantCategory('')}>
                <MdAdd />
              </Button>
            </Flex>
          </List>
        </Card>
        <Card p={3} w="100%" mt={4}>
          <FormLabel>Irrelevant categories</FormLabel>
          <List>
            <Box borderLeft="3px solid" borderColor="danger" pl={4}>
              {state.irrelevantDimensions.map((category, index) => (
                <Flex key={index} mb={2} alignItems="center">
                  <Textarea
                    minH="36px"
                    lineHeight="1.5"
                    mb={0}
                    value={category}
                    onChange={(e) =>
                      updateIrrelevantCategory(index, e.target.value)
                    }
                  />
                  <IconButton
                    aria-label="remove"
                    size="sm"
                    variant="ghost"
                    _hover={{ backgroundColor: 'none' }}
                    _focus={{ backgroundColor: 'none' }}
                    onClick={() => removeIrrelevantCategory(index)}
                    icon={<MdClose />}
                  />
                </Flex>
              ))}
            </Box>
            <Flex justify="center">
              <Button
                primary
                onClick={() => handleAddNewIrrelevantCategory('')}
              >
                <MdAdd />
              </Button>
            </Flex>
          </List>
        </Card>
      </VStack>
      <Flex justify="space-between" w="100%" mt={3}>
        <Button
          isDisabled={!hasChanges}
          isLoading={isUpdatePending}
          onClick={() => handleUpdateKeyTopicsDiscovery(state)}
          primary
        >
          Update discovery
        </Button>
        <Button
          isLoading={isDeletePending}
          onClick={() => handleDeleteKeyTopicDiscovery()}
          colorScheme="red"
        >
          Delete discovery
        </Button>
      </Flex>
    </VStack>
  )
}

export default KeyTopicDiscoveryForm
