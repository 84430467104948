import { GlobalReport } from '@capturi/api-systemadmin'
import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { apiVersion } from '../constants'

export type GlobalReportResponse = {
  reports: GlobalReport[]
}

type Deps = {
  uid: string
  organizationUid: string
}

export type ReportDeps = {
  dependents: Deps[]
}

const baseUrl = 'systemadmin/metabase/standard-reports'
const cacheKey = 'global-reports'

export const useGlobalReports = (): UseQueryResult<
  GlobalReportResponse,
  ResponseError
> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: async () => {
      const result = await request.get<{ reports: GlobalReportResponse }>(
        `${baseUrl}/list/${apiVersion}`,
      )
      return result
    },
  })

export const useGlobalReportDeps = (
  reportUid: string,
): UseQueryResult<ReportDeps, ResponseError> =>
  useQuery({
    queryKey: [cacheKey, 'deps', reportUid],
    queryFn: () =>
      request.get<ReportDeps>(
        `${baseUrl}/${reportUid}/dependents${apiVersion}`,
      ),
  })
