import { useMyOrganizations } from '@capturi/api-me'
import { useCurrentUser } from '@capturi/core'
import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { ReactElement, useMemo } from 'react'
import { useParams } from 'react-router'

import Loader from '../../../Loader'
import { useSingleOrganization } from '../../../shared/useOrganizations'
import { User, useCapturiUsers } from '../useUsers'
import AccessModal from './AccessModal'

const Access: React.FC = () => {
  const { uid: organizationUid } = useParams()
  const [open] = useModal(AccessModal)
  const { name, email = '', title = '', id: currentUserId } = useCurrentUser()
  const { data: myOrgs } = useMyOrganizations()
  const { data, isLoading, error } = useCapturiUsers(organizationUid)
  const { data: selectedOrg } = useSingleOrganization(organizationUid)
  const currentUsers = useMemo(
    () => (data || []).filter((u) => u.inviteStatus === 'accepted'),
    [data],
  )
  const previousUsers = useMemo(
    () =>
      (data || []).filter((u) => u.isDeleted || u.inviteStatus !== 'accepted'),
    [data],
  )
  const userIsInOrg = useMemo(
    () => (myOrgs ? myOrgs.some((o) => o.id === organizationUid) : true),
    [myOrgs, organizationUid],
  )

  if (!organizationUid) return null

  if (error)
    return (
      <Text textAlign="center" mt="33%" fontSize="xl" color="red.800">
        {error.error}
      </Text>
    )
  if (isLoading) return <Loader />

  const renderUser = ({ id, email, name, title }: User): ReactElement => {
    return (
      <Box key={id} m="2">
        <Text fontWeight="light" color={'gray.700'}>
          {title}
        </Text>
        <Text
          fontWeight="500"
          color={id === currentUserId ? 'primary.500' : undefined}
        >
          {name}
        </Text>
        <Text ml="1">{email}</Text>
      </Box>
    )
  }

  return (
    <Box>
      {selectedOrg?.limitedAccess ? (
        <Text my="12" textAlign="center" fontSize="lg" color="red.800">
          Limited Access, ask before you do anything
        </Text>
      ) : (
        <Flex justifyContent="flex-end">
          <Button
            title={
              userIsInOrg ? 'You are already in this organization' : undefined
            }
            isDisabled={userIsInOrg || selectedOrg?.limitedAccess}
            primary
            onClick={() => open({ email, name, organizationUid, title })}
          >
            Get Access
          </Button>
        </Flex>
      )}

      <Box>
        <Text fontSize="xl">
          Current Capturi Users in Organization ({currentUsers.length})
        </Text>
        {currentUsers.length === 0 ? (
          <Text mt="6" fontSize="lg" textAlign="center">
            No Current users
          </Text>
        ) : (
          currentUsers.map(renderUser)
        )}
        <Text mt="8" fontSize="xl">
          Previous Capturi Users in Organization ({previousUsers.length})
        </Text>
        {previousUsers.length === 0 ? (
          <Text mt="6" fontSize="lg" textAlign="center">
            No Previous users
          </Text>
        ) : (
          previousUsers.map(renderUser)
        )}
      </Box>
    </Box>
  )
}

export default Access
