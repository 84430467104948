import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
} from '@chakra-ui/react'
import {
  SystemSettings,
  useUpdateSystemSettings,
} from '../../../../../shared/useSystemSettings'

import React, { useState } from 'react'

type Props = {
  organizationUid: string
  isEnabled: boolean
  aiData: SystemSettings['ai']
}

const KeytopicThreshold: React.FC<Props> = ({
  organizationUid,
  isEnabled,
  aiData,
}) => {
  const toast = useToast()

  const [threshold, setThreshold] = useState<string | null>(
    aiData.keyTopicThreshold ? String(aiData.keyTopicThreshold) : null,
  )

  const { mutate: updateSystemSettings } =
    useUpdateSystemSettings(organizationUid)

  const handleSaveThreshold = (threshold: number | null): void => {
    updateSystemSettings(
      { ai: { ...aiData, keyTopicThreshold: threshold } },
      {
        onSuccess: () => {
          toast({
            status: 'success',
            title: 'Successfully updated key topic threshold',
          })
        },
        onError: () => {
          toast({
            status: 'error',
            title: 'failed to update key topic threshold',
          })
        },
      },
    )
  }

  const hasChanges =
    threshold === null
      ? aiData.keyTopicThreshold !== threshold
      : aiData.keyTopicThreshold !== Number(threshold)

  return (
    <Box>
      <FormControl mb={4} isDisabled={!isEnabled}>
        <FormLabel>Use custom clustering threshold</FormLabel>
        <Checkbox
          isChecked={threshold !== null}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setThreshold('0')
            } else {
              setThreshold(null)
            }
          }}
        />
      </FormControl>
      <FormControl isDisabled={!isEnabled}>
        <FormLabel>Threshold value</FormLabel>
        <NumberInput
          isDisabled={threshold === null}
          w="100px"
          max={1.0}
          step={0.01}
          inputMode="decimal"
          onChange={(value) => {
            setThreshold(value)
          }}
          value={threshold ?? undefined}
          size="sm"
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <Button
        colorScheme="primary"
        mt={2}
        isDisabled={!(isEnabled && hasChanges)}
        onClick={() =>
          handleSaveThreshold(threshold ? Number.parseFloat(threshold) : null)
        }
      >
        Save threshold
      </Button>
    </Box>
  )
}

export default KeytopicThreshold
