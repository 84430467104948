import { Button } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Icon,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import React, { ReactElement, useState } from 'react'
import {
  MdDelete,
  MdEdit,
  MdInfoOutline,
  MdKey,
  MdKeyOff,
} from 'react-icons/md'
import { useParams } from 'react-router'
import Loader from '../../../Loader'
import { RefreshButton } from '../../../RefreshButton'
import { convertToCsv } from '../../../utils/convertToCsv'
import { User, useUsers } from '../useUsers'
import EditUserDrawer from './EditUserDrawer'
import { UserPermissions, defaultUserPermissions } from './permissionsUtils'

const getStatus = ({
  inviteStatus,
  isDeleted,
}: {
  isDeleted: boolean
  inviteStatus: 'unknown' | 'accepted' | 'sent'
}): ReactElement => {
  if (isDeleted)
    return <MdDelete size={15} color="red" title="User is Deleted" />
  if (inviteStatus === 'accepted')
    return (
      <MdKey size={15} color="orange" title="User has access to the platform" />
    )
  return (
    <MdKeyOff size={15} title="User does NOT have access to the platform" />
  )
}

const Users: React.FC = () => {
  const { uid: organizationUid } = useParams()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const { data, refetch, isFetching } = useUsers(organizationUid)
  const [user, setUser] = useState<User | null>(null)

  const filterFunction = (u: User): boolean => {
    if (u.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
      return true
    if (u.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
      return true
    if (u.role.includes(searchTerm)) return true
    if (
      u.team?.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    )
      return true
    if (u.permissions[searchTerm as keyof UserPermissions]) return true
    if (
      u.externalUids?.some((uid) =>
        uid.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      )
    )
      return true
    return false
  }

  if (organizationUid == null) return <div>Not found</div>

  const renderRow = (row: User, index: number): ReactElement => {
    const {
      email,
      mostRecentConversation,
      name,
      role,
      inviteStatus,
      isDeleted,
      externalUids,
    } = row

    return (
      <Tr cursor="pointer" key={index} onClick={() => setUser(row)}>
        <Td>
          <MdEdit color="#2F96D0" />
        </Td>
        <Td>{name}</Td>
        <Td>{email}</Td>
        <Td
          maxW="100px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {(externalUids || []).join(', ')}
        </Td>
        <Td>{role}</Td>
        <Td>{mostRecentConversation?.toLocaleString()}</Td>
        <Td>{getStatus({ inviteStatus, isDeleted })}</Td>
      </Tr>
    )
  }

  if (!data) return <Loader />
  const activeUsers = data.filter(filterFunction)

  const copyAsJSON = () => {
    navigator.clipboard.writeText(JSON.stringify(activeUsers, undefined, '   '))
  }
  const copyAsCsv = () => {
    const csv = convertToCsv(activeUsers)
    navigator.clipboard.writeText(csv)
  }

  return (
    <Box>
      <Flex justifyContent="space-between" mb="4">
        <Flex w="100%" alignItems="center">
          <Input
            placeholder={'search'}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            width="30%"
            height={8}
            mr="2"
          />
          <Tooltip
            hasArrow
            label={`Try searching for a permission: ${Object.keys(
              defaultUserPermissions,
            ).join(', ')} or a role: user teamlead administrator`}
          >
            <span>
              <Icon as={MdInfoOutline} />
            </span>
          </Tooltip>
        </Flex>
        <Button variant="outline" onClick={copyAsJSON}>
          Copy as JSON
        </Button>
        <Button variant="outline" onClick={copyAsCsv} mx="2">
          Copy as CSV
        </Button>
        <RefreshButton
          refresh={refetch}
          isLoading={isFetching}
          position="initial"
        />
      </Flex>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th />
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>External Uids</Th>
            <Th>Role</Th>
            <Th>Most Recent Conversation</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>{activeUsers.map(renderRow)}</Tbody>
      </Table>
      <EditUserDrawer user={user} onClose={() => setUser(null)} />
    </Box>
  )
}

export default Users
