import type { ResponseError } from '@capturi/request'
import request from '@capturi/request'
import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export type Masterdata = {
  customer: {
    customerKey: string
    billingAddress: string
    description: null | string
    isDeleted: null | boolean
    name: string
    createdTimestamp: Date
    vatNumber: string
    category: null | string
    contract: string
    country: string
    currency: string
    partnerId: null | string
    isProspect: boolean
    platformArea: string
  }
  isDeleted: boolean
  isProvisioned: boolean
  id: string
  externalId: string
  customerKey: string
  name: string
  description: string
  platform: 'Analytics' | string
  country: string
  platformArea: string
}

export const useMasterdata = <T = Masterdata[]>({
  select,
}: {
  select?: ((data: Masterdata[]) => T) | undefined
} = {}): UseQueryResult<T, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'masterdata'],
    queryFn: () =>
      request.get<Masterdata[]>('masterdata-provisioning/v1', {
        searchParams: { All: true },
      }),
    select,
  })
export const useMarkAsProvisioned = (): UseMutationResult<
  Masterdata,
  ResponseError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id) =>
      request.post<Masterdata>(`masterdata-provisioning/v1/${id}/provisioned`),
    onSuccess: (data) => {
      queryClient.setQueryData<Masterdata[]>(
        ['superpowers', 'masterdata'],
        (oldData) => {
          if (!oldData) {
            return [data]
          }
          return oldData.map((d) => (d.id === data.id ? { ...d, ...data } : d))
        },
      )
    },
  })
}
