import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Input, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import type { Integration } from '../../shared/types'
import SftpModal from './SftpModal'
import { FilesOnSftpModal } from './SftpModal/FilesOnFtpModal'

type Props = { integrations: Integration[] }

const Support: React.FC<Props> = ({ integrations }) => {
  const [openSftp] = useModal(SftpModal)
  const [openSftpList] = useModal(FilesOnSftpModal)
  const [limit, setLimit] = useState('')

  const renderIntegration = ({
    integrationKey,
    integrationType,
  }: Integration) => {
    return (
      <Box margin="2" key={integrationKey + integrationType}>
        <Text fontWeight="medium" m="1">
          {integrationKey} {integrationType}
        </Text>
        <Box>
          <Button
            width="244px"
            my="3"
            borderEndEndRadius={0}
            borderTopEndRadius={0}
            onClick={() => {
              openSftpList({
                integrationKey,
                integrationType,
                showPathChooser: true,
              })
            }}
          >
            View SFTP
          </Button>
          <Flex>
            <Button
              borderEndEndRadius={0}
              borderTopEndRadius={0}
              onClick={() => {
                openSftp({ integrationKey, integrationType, limit })
              }}
            >
              Inspect files
            </Button>
            <Input
              width="150px"
              borderEndStartRadius={0}
              borderTopStartRadius={0}
              size="sm"
              placeholder="Number of files (20)"
              onChange={(e) => setLimit(e.currentTarget.value)}
              value={limit}
            />
          </Flex>
        </Box>
      </Box>
    )
  }

  return (
    <Box mt="2">
      <Text fontSize="xl">🧑‍🔧 Support stuff</Text>
      {integrations.map(renderIntegration)}
    </Box>
  )
}

export default Support
