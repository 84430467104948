import { FormControl, FormLabel, Select } from '@chakra-ui/react'
import React from 'react'
import { AudioChannelOptions } from '../../../integrations'
import { AudioChannel } from '../../../shared/types'

type Props = {
  options: AudioChannelOptions
  inProgress: boolean
  setState: (key: AudioChannel | '') => void
  state: AudioChannel | ''
}
const defaultChannels: AudioChannel[] = [
  'Mono1Speaker',
  'Mono2Speaker',
  'Stereo',
]
const AudioChannelConfig: React.FC<Props> = ({
  options,
  inProgress,
  state,
  setState,
}) => {
  const { defaultValue, disable, values } = options
  const opt = values || defaultChannels

  return (
    <FormControl mt="2">
      <FormLabel htmlFor="audioChannel">Audio Channel</FormLabel>
      <Select
        id="audioChannel"
        onChange={(e) => setState(e.currentTarget.value as AudioChannel)}
        value={state || defaultValue}
        isDisabled={inProgress || disable}
        placeholder="Select option"
      >
        {opt.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default AudioChannelConfig
