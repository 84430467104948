import analytics from '@capturi/analytics'
import { ReportFormModel } from '@capturi/api-systemadmin'
import { useInput } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import {
  Box,
  Flex,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { type FC, useEffect } from 'react'
import {
  useDeleteGlobalReport,
  useUpdateGlobalReport,
} from './useGlobalReportActions'
import { useGlobalReportDeps } from './useGlobalReports'

type Props = {
  organizationUid: string | undefined
  title: string
  metabaseId: string
  description: string
  reportUid: string
}

export const GlobalReportCard: FC<Props> = ({
  title,
  metabaseId,
  description,
  reportUid,
}) => {
  const [state, setState] = useInput({
    title: title,
    description: description,
    metabaseId: metabaseId,
  })
  const toast = useToast()

  const { data: reportDeps } = useGlobalReportDeps(reportUid)

  const { mutate: updateReport, isPending: isUpdateLoading } =
    useUpdateGlobalReport()
  const { mutate: deleteReport, isPending: isDeleteLoading } =
    useDeleteGlobalReport()

  const inProgress = isUpdateLoading || isDeleteLoading

  const handleUpdate = (
    formData: Omit<ReportFormModel, 'host'>,
    reportUid?: string,
  ): void => {
    if (reportUid) {
      const parsedId = Number.parseInt(formData.metabaseId)
      updateReport(
        {
          reportUid: reportUid,
          report: { ...formData, metabaseId: parsedId },
        },
        {
          onSuccess: () => {
            toast({
              title: `${formData.title} updated successfully`,
              status: 'success',
            })
          },
          onError: (error) => {
            toast({
              title: 'Could not update Metabase report',
              status: 'error',
              description: error.message,
            })
          },
        },
      )
    }
  }
  const handleDelete = (reportUid: string): void => {
    deleteReport(reportUid, {
      onSuccess: () => {
        toast({
          title: 'Report deleted successfully',
          status: 'success',
        })
      },
      onError: (error) => {
        toast({
          title: 'Could not delete Metabase report',
          status: 'error',
          description: error.message,
        })
      },
    })
  }

  useEffect(() => {
    analytics.event('reports_current_for_org', {
      metabaseId,
      reportUid,
      title,
    })
  }, [reportUid, metabaseId, title])

  return (
    <SimpleGrid
      columns={1}
      spacing={3}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="6px"
      p={4}
    >
      <Box alignItems="flex-start">
        <FormLabel>Title</FormLabel>
        <Input
          {...setState.title}
          isDisabled={inProgress}
          placeholder="Title"
        />
      </Box>
      <Box alignItems="flex-start">
        <FormLabel>Description</FormLabel>
        <Input
          {...setState.description}
          isDisabled={inProgress}
          placeholder="Description"
        />
      </Box>
      <Box alignItems="flex-start">
        <Text>
          <FormLabel>Id</FormLabel>
        </Text>
        <Input
          {...setState.metabaseId}
          isDisabled={inProgress}
          placeholder="Id"
        />
      </Box>
      <Flex justifyContent="space-between">
        <Box>
          <Tooltip
            hasArrow
            placement="top"
            label="This report is associated with an org. Remove the association to be able to delete it"
            isDisabled={!reportDeps?.dependents.length}
          >
            <Button
              size="sm"
              colorScheme="red"
              isLoading={isDeleteLoading}
              isDisabled={isUpdateLoading || !!reportDeps?.dependents.length}
              onClick={() => handleDelete(reportUid)}
            >
              Delete
            </Button>
          </Tooltip>
          <Button
            ml={2}
            primary
            isLoading={isUpdateLoading}
            isDisabled={isDeleteLoading}
            onClick={() => handleUpdate(state, reportUid)}
          >
            Update
          </Button>
        </Box>
      </Flex>
    </SimpleGrid>
  )
}
