import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Card, CardBody, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { Link } from 'react-router'
import { type Masterdata } from '../shared/useMasterdata'
import { useSingleOrganization } from '../shared/useOrganizations'
import { useSystemSettings } from '../shared/useSystemSettings'
import { ConfirmMarkAsProvisionedModal } from './ConfirmMarkAsProvisionedModal'

type Props = { provision: Masterdata }

export const DeleteCard: FC<Props> = ({ provision }) => {
  const { data: existingOrg } = useSingleOrganization(provision.externalId)
  const { data: systemSettings } = useSystemSettings(provision.externalId)
  const [showConfirmMarkAsProvisionedModal] = useModal(
    ConfirmMarkAsProvisionedModal,
  )

  const canBeMarkedAsDeleted = !!systemSettings?.deleted || !!existingOrg

  return (
    <Card key={provision.id} my="4">
      <CardBody
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <div>
          <Text color="gray.600" fontStyle="italic">
            {provision.country}
          </Text>
          <Text>
            {provision.name} - {provision.description}
          </Text>

          <Text color="gray.600">{provision.customer.name}</Text>
        </div>
        {canBeMarkedAsDeleted ? (
          <Button
            colorScheme="primary"
            onClick={() =>
              showConfirmMarkAsProvisionedModal({
                organizationName: existingOrg?.name || '',
                provisionId: provision.id,
                type: 'deleted',
              })
            }
          >
            Mark as Deleted
          </Button>
        ) : (
          <Link to={`/superpowers/organization/${provision.externalId}`}>
            <Button variant={'outline'} colorScheme="primary">
              Go to Organization
            </Button>
          </Link>
        )}
      </CardBody>
    </Card>
  )
}
