import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import type {
  ConfigField,
  IntegrationConfigSchema,
} from './IntegrationConfigSchemaType'
import { ContinuationTokenField } from './fields/ContinuationTokenField'
import { Field } from './fields/Field'
import { useUpdateAudioImporter } from './useIntegrationConfig'

type Props = {
  schema: IntegrationConfigSchema
  currentCustomFields: Record<string, string>
  currentIntegrationOptions: Record<string, string | string[] | boolean>
  currentContinuationToken: string | Date
  integrationType: string
  integrationKey: string
  onClose: () => void
}

const extractDefaultValues = (fields: ConfigField[] = []) => {
  return fields.reduce<Record<string, unknown>>((memo, f) => {
    memo[f.key] = f.defaultValue

    return memo
  }, {})
}
export const IntegrationConfig: React.FC<Props> = ({
  schema,
  currentIntegrationOptions,
  currentCustomFields,
  currentContinuationToken,
  integrationType,
  integrationKey,
  onClose,
}) => {
  const toast = useToast()
  const { mutate, isPending } = useUpdateAudioImporter(
    integrationType,
    integrationKey,
  )

  // biome-ignore lint/suspicious/noExplicitAny: could be nice to fix with something smarter
  const [continuationToken, setContinuationToken] = useState<any>(
    currentContinuationToken,
  )

  const [integrationOptions, setIntegrationOptions] = useState<
    Record<string, unknown>
  >({
    ...extractDefaultValues(schema.integrationOptions),
    ...currentIntegrationOptions,
  })
  const [customFields, setCustomFields] = useState<Record<string, unknown>>({
    ...extractDefaultValues(schema.customFields),
    ...currentCustomFields,
  })

  const handleUpdate = () => {
    mutate(
      { customFields, integrationOptions, continuationToken },
      {
        onSuccess: () => {
          toast({ status: 'success', title: 'Integration Updated' })
          onClose()
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: error.name,
            description: error.message,
          })
        },
      },
    )
  }

  return (
    <ModalContent>
      <ModalHeader>
        {integrationType}: {integrationKey} Config
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <SimpleGrid columns={2} spacing="6">
          <div>
            <ContinuationTokenField
              value={continuationToken}
              onChange={setContinuationToken}
              type={schema.continuationToken}
              isDisabled={isPending}
            />
            {(schema.integrationOptions || schema.options || []).map((f) => (
              <Box my="3" key={f.key}>
                <Field
                  key={f.key}
                  field={f}
                  onChange={(v) =>
                    setIntegrationOptions((s) => ({ ...s, [f.key]: v }))
                  }
                  value={integrationOptions[f.key]}
                />
              </Box>
            ))}
          </div>
          <div>
            {schema.customFields.map((f) => (
              <Box my="3" key={f.key}>
                <Field
                  key={f.key}
                  field={f}
                  onChange={(v) => {
                    setCustomFields((s) => ({ ...s, [f.key]: v }))
                  }}
                  value={customFields[f.key]}
                />
              </Box>
            ))}
          </div>
        </SimpleGrid>
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <Button mr="4" isDisabled={isPending} onClick={onClose}>
          Cancel
        </Button>
        <Button primary isLoading={isPending} onClick={handleUpdate}>
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
