import { Select, useToast } from '@chakra-ui/react'
import React from 'react'
import {
  AiProvider,
  SystemSettings,
  useUpdateSystemSettings,
} from '../../../../../shared/useSystemSettings'

type Props = { organizationUid: string; ai: SystemSettings['ai'] }

const AiProviderSelect: React.FC<Props> = ({ organizationUid, ai }) => {
  const toast = useToast()

  const { mutate: updateSystemSettings } =
    useUpdateSystemSettings(organizationUid)

  const handleSaveProvider = (provider: AiProvider): void => {
    updateSystemSettings(
      { ai: { ...ai, provider: provider } },
      {
        onSuccess: () => {
          toast({
            status: 'success',
            title: 'Successfully updated AI provider',
          })
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: 'failed to update AI provider',
            description: error.message,
          })
        },
      },
    )
  }
  return (
    <Select
      value={ai.provider ?? 'OpenAI'}
      onChange={(e) => handleSaveProvider(e.target.value as AiProvider)}
    >
      <option value="OpenAI">OpenAI WITH anonymization</option>
      <option value="AzureWithAnonymization">Azure WITH anonymization</option>
      <option value="AzureWithoutAnonymization">
        Azure WITHOUT anonymization
      </option>
    </Select>
  )
}

export default AiProviderSelect
