import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
} from '@chakra-ui/react'
import React from 'react'

import { ResponseError } from '@capturi/request'
import { Spinner } from '@capturi/ui-components'
import { useNavigate } from 'react-router'
import { HeimdalData, useMute } from './useHeimdalStatus'

type Props = {
  title: string
  color: string
  rows: HeimdalData[] | undefined
  isLoading: boolean
  error: ResponseError | null
}

const formatPeriod = (from: Date, to: Date) => {
  return `${from.toLocaleTimeString()} - ${to.toLocaleTimeString()}`
}

const MissingColumn: React.FC<Props> = ({
  rows,
  title,
  color,
  isLoading,
  error,
}) => {
  return (
    <div>
      <Heading pb="2" size="2xl" color="GrayText">
        {title}
      </Heading>
      {error && <Text>{error.message}</Text>}
      {isLoading && <Spinner />}
      {rows?.map((r) => (
        <MissingCard {...r} key={r.organizationUid} color={color} />
      ))}
    </div>
  )
}

const MissingCard: React.FC<HeimdalData & { color: string }> = ({
  avg,
  name,
  organizationUid,
  from,
  to,
  color,
}) => {
  const { mutate, isPending } = useMute()
  const navigate = useNavigate()

  return (
    <Card size="sm" mb="4" borderLeft={`solid 3px ${color}`}>
      <CardHeader display="flex" justifyContent="space-between">
        <Heading size="xl">{name}</Heading>
        <Text>{avg.toFixed(2)}</Text>
      </CardHeader>
      <CardBody>
        <Text>Periode {formatPeriod(from, to)}</Text>
        <Text>No conversations imported in periode.</Text>
        <Text>
          {' '}
          Normal average in periode <b>{avg.toFixed(2)}</b>
        </Text>
      </CardBody>
      <CardFooter justifyContent="space-between">
        <Button
          colorScheme="primary"
          variant="ghost"
          onClick={() => {
            navigate(`/superpowers/organization/${organizationUid}`)
          }}
        >
          View Organization
        </Button>
        <Button
          colorScheme="gray"
          variant="ghost"
          isLoading={isPending}
          onClick={() => {
            mutate({ organizationUid, muteEndTime: new Date(10000000000000) })
          }}
        >
          Mute
        </Button>
      </CardFooter>
    </Card>
  )
}

export default MissingColumn
