import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export type Customer = {
  updated: Date
  updatedByUserUid: string
  customer: string
}

export const useGlobalRepeatCallsBlacklist = (): UseQueryResult<Customer[]> =>
  useQuery({
    queryKey: ['superpowers', 'repeat-calls-blacklist'],
    queryFn: async () => {
      const result = await request.get<{ customers: Customer[] }>(
        'systemadmin/repeat-calls/globalblacklist',
      )
      return result.customers
    },
  })

export const UseUpdateGlobalRepeatCallsblacklist = (): UseMutationResult<
  Customer,
  ResponseError,
  string[],
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['superpowers', 'repeat-calls-blacklist'],
    mutationFn: (model) =>
      request.patch('systemadmin/repeat-calls/globalblacklist', {
        json: { customers: model },
      }),
    onSuccess: (blacklist) => {
      queryClient.setQueryData<Customer[]>(
        ['superpowers', 'repeat-calls-blacklist'],
        (oldData) => {
          if (!oldData) {
            return [blacklist]
          }
          return [...oldData, blacklist]
        },
      )
    },
  })
}
