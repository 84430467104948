import { useInput } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import { Button, Flex, Input, Select, Text } from '@chakra-ui/react'
import queryString from 'query-string'
import React, { ChangeEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router'

const UploadTranscription: React.FC = () => {
  const [state, setState] = useInput({ resultUid: '' })
  const [spokenLanguage, setSpokenLanguage] = useState('DaDk')
  const [stereoSalesChannel, setStereoSalesChannel] = useState<string>('')
  const [audiofile, setAudiofile] = useState<File | null>(null)
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files) {
      setAudiofile(e.target.files[0])
    }
  }

  const handleUpload = async (): Promise<void> => {
    if (audiofile) {
      try {
        setError(null)
        setInProgress(true)
        const form = new FormData()
        form.append('file', audiofile)

        const url = queryString.stringifyUrl({
          url: 'superpowers/asr/transcription/custom',
          query: {
            spokenLanguage: spokenLanguage,
            stereoSalesChannel: stereoSalesChannel || undefined,
          },
        })

        const { uid } = await request.post<{ uid: string }>(url, {
          body: form,
        })

        navigate(`${uid}/${spokenLanguage}`)
      } catch (error) {
        setInProgress(false)
        if (error instanceof ResponseError) {
          setError(error.message)
        } else {
          setError('Unknown error')
        }
      }
    }
  }

  return (
    <div>
      <Flex mt="10" flexDirection="column" alignItems="center">
        <Text mb="4" fontSize="xl">
          Upload Audiofile for Transcription
        </Text>
        <input type="file" onChange={handleFileChange} />
        <Flex mt="2">
          <Select
            size="sm"
            width={'fit-content'}
            onChange={(e) => setSpokenLanguage(e.currentTarget.value)}
            value={spokenLanguage}
          >
            {['DaDk', 'EnUs', 'DeDe', 'NbNo', 'SvSe', 'FiFi'].map((l) => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </Select>
          <Select
            size="sm"
            width={'fit-content'}
            placeholder="Stereo Agent Channel"
            onChange={(e) => setStereoSalesChannel(e.currentTarget.value)}
            value={stereoSalesChannel}
          >
            <option value={'1'}>Left (1)</option>
            <option value={'2'}>Right (2)</option>
          </Select>
          <Button
            colorScheme="green"
            onClick={handleUpload}
            borderRadius="0 1px 1px 0"
            isLoading={inProgress}
            isDisabled={audiofile === null}
          >
            Upload
          </Button>
        </Flex>
        {error && (
          <Text mt="2" textAlign="center" color="red.500">
            {error}
          </Text>
        )}
      </Flex>

      <Flex mt="40" flexDirection="column" alignItems="center">
        <Text mb="4" fontSize="2xl">
          View Transcription
        </Text>

        <Flex mt="2">
          <Input
            {...setState.resultUid}
            size="sm"
            borderRadius="20px 0 0 20px"
            width="80"
            placeholder="43dade5a-ec60-4acc-8139-42defd38eea3"
          />
          <Select
            size="sm"
            width="auto"
            onChange={(e) => setSpokenLanguage(e.currentTarget.value)}
            value={spokenLanguage}
          >
            {['DaDk', 'EnUs', 'DeDe', 'NbNo', 'SvSe', 'FiFi'].map((l) => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </Select>
          <Button
            colorScheme="primary"
            as={Link}
            to={`${state.resultUid}/${spokenLanguage}`}
            borderRadius="0 20px 20px 0"
          >
            Go
          </Button>
        </Flex>
      </Flex>
    </div>
  )
}

export default UploadTranscription
