import { Grid, Text, VStack } from '@chakra-ui/react'
import React from 'react'

import { Title } from '../../../../utils'

import { useParams } from 'react-router'
import StandardReport from './StandardReport'
import { useStandardReports } from './useStandardReports'

const StandardReports: React.FC = () => {
  const { uid: organizationUid } = useParams()
  const { data } = useStandardReports(organizationUid)

  if (organizationUid === undefined) {
    return null
  }

  return (
    <VStack mt={8} mb="20" alignItems="flex-start" spacing={6}>
      <Title>Standard Metabase reports</Title>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
        {data?.length ? (
          data.map((report) => (
            <StandardReport
              key={report.uid}
              title={report.title}
              enabled={report.enabled}
              description={report.description}
              reportUid={report.uid}
              organizationUid={organizationUid}
            />
          ))
        ) : (
          <Text>No reports</Text>
        )}
      </Grid>
    </VStack>
  )
}

export default StandardReports
