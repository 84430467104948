import { Button } from '@capturi/ui-components'
import { Box, Card, CardBody, Collapse, Flex, Text } from '@chakra-ui/react'
import {
  type FC,
  type ReactElement,
  type ReactNode,
  useCallback,
  useState,
} from 'react'
import {
  type Masterdata,
  useMasterdata,
} from '../../../../shared/useMasterdata'
import { Title } from '../../../../utils'

type Props = { organizationUid: string }

export const SmartRow = ([key, value]: [string, unknown]): ReactElement => {
  let formattedValue: string | number = ''

  if (value instanceof Date) {
    formattedValue = value.toLocaleString()
  } else if (typeof value === 'boolean') {
    formattedValue = value ? 'true' : 'false'
  } else if (typeof value === 'string' || typeof value === 'number') {
    formattedValue = value
  } else if (Array.isArray(value)) {
    formattedValue = value.join(', ')
  }
  return Row(key, formattedValue)
}

export const Row = (key: string, value: ReactNode): ReactElement => {
  return (
    <Box as="tr" key={key}>
      <Box textAlign="right" as="td" color="gray" pr="2">
        {key}
      </Box>
      <Box as="td" fontWeight="bold">
        {value}
      </Box>
    </Box>
  )
}

export const MasterdataInfo: FC<Props> = ({ organizationUid }) => {
  const [showMore, setShowMore] = useState(false)
  const select = useCallback(
    (d: Masterdata[]) => d.find((d) => d.externalId === organizationUid),
    [organizationUid],
  )
  const { data } = useMasterdata({ select: select })

  if (!data) return null

  const { customer, ...solution } = data
  return (
    <Card mt="4">
      <CardBody>
        <Flex alignItems="center" justifyContent="space-between">
          <div>
            <Title>
              {customer.name} ({customer.customerKey})
            </Title>
            <Text>
              {solution.name} - {solution.description}
            </Text>
          </div>
          <Button variant="outline" onClick={() => setShowMore((f) => !f)}>
            {showMore ? 'Show less' : 'Show more'}
          </Button>
        </Flex>

        <Collapse in={showMore} animateOpacity>
          <Flex direction={['column', 'column', 'column', 'row']}>
            <Box p="4">
              <Text fontSize="lg">Customer</Text>

              <table>
                <tbody>{Object.entries(customer).map(SmartRow)}</tbody>
              </table>
            </Box>
            <Box p="4">
              <Text fontSize="lg">Solution</Text>

              <table>
                <tbody>{Object.entries(solution).map(SmartRow)}</tbody>
              </table>
            </Box>
          </Flex>
        </Collapse>
      </CardBody>
    </Card>
  )
}
