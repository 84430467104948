import { ReportFormModel } from '@capturi/api-systemadmin'
import { useInput } from '@capturi/react-utils'
import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  FormLabel,
  Grid,
  Input,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { type FC } from 'react'

import { useOrganization } from '@capturi/stores'
import { Title } from '../../../../utils'
import { GlobalReportCard } from './GlobalReport'
import { useCreateGlobalReport } from './useGlobalReportActions'
import { useGlobalReports } from './useGlobalReports'

const GlobalReports: FC = () => {
  const { uid: organizationUid } = useOrganization()
  const { data: globalReports } = useGlobalReports()
  const { mutate: createReport, isPending: isCreateLoading } =
    useCreateGlobalReport()

  const [state, setState] = useInput({
    title: '',
    description: '',
    metabaseId: '',
  })

  const toast = useToast()
  const handleCreate = (formData: Omit<ReportFormModel, 'host'>): void => {
    createReport(
      {
        report: {
          ...formData,
          metabaseId: Number.parseInt(formData.metabaseId, 10),
        },
      },
      {
        onSuccess: () => {
          toast({
            title: `${formData.title} report created successfully`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: 'Could not create Metabase report',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  return (
    <VStack mt={8} mb="20" alignItems="flex-start" spacing={6}>
      <Title>Create Global Metabase report</Title>
      <SimpleGrid
        columns={1}
        spacing={3}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="6px"
        p={4}
      >
        <Box alignItems="flex-start">
          <Text>
            <FormLabel>Title</FormLabel>
          </Text>
          <Input
            {...setState.title}
            isDisabled={isCreateLoading}
            placeholder="Title"
          />
        </Box>
        <Box alignItems="flex-start">
          <Text>
            <FormLabel>Description</FormLabel>
          </Text>
          <Input
            {...setState.description}
            isDisabled={isCreateLoading}
            placeholder="Description"
          />
        </Box>
        <Box alignItems="flex-start">
          <Text>
            <FormLabel>Id</FormLabel>
          </Text>
          <Input
            type="number"
            {...setState.metabaseId}
            isDisabled={isCreateLoading}
            placeholder="Id"
          />
        </Box>
        <Box>
          <Button
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            primary
            onClick={() => handleCreate(state)}
          >
            Create report
          </Button>
        </Box>
      </SimpleGrid>
      <Title>Global Metabase reports</Title>
      <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
        {globalReports ? (
          globalReports?.reports.map((report) => (
            <GlobalReportCard
              key={report.uid}
              title={report.title}
              description={report.description}
              metabaseId={report.metabaseId.toString()}
              reportUid={report.uid}
              organizationUid={organizationUid}
            />
          ))
        ) : (
          <Text>No reports</Text>
        )}
      </Grid>
    </VStack>
  )
}

export default GlobalReports
