import { useSet } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router'
import useSWR from 'swr'

import ConfirmRemoveLabelsModal from './ConfirmRemoveLabelsModal'

type LabelsResponse = {
  result: string[]
  errorCode: string
  errorMessage: string
  hasError: boolean
}

const RemoveLabels: React.FC = () => {
  const { uid: organizationUid = '' } = useParams()

  const [showConfirm] = useModal(ConfirmRemoveLabelsModal)
  const labelsToRemove = useSet<string>([])

  const { data: labelResult, mutate } = useSWR<LabelsResponse>(
    `systemadmin/list-labels/${organizationUid}`,
    { suspense: false },
  )

  const onRemove = (): void => {
    showConfirm({
      labelsToRemove: [...labelsToRemove],
      organizationUid: organizationUid,
      onSubmit: () => {
        mutate(
          (l) =>
            l?.result && {
              ...l,
              result: l.result.filter((label) => !labelsToRemove.has(label)),
            },
        )
        labelsToRemove.clear()
      },
    })
  }

  const labels = labelResult?.result || []

  return (
    <div>
      <Flex justifyContent="space-between">
        <div>
          <Text fontSize="xl"> Select labels you want to remove</Text>
          <Text color="subtitle">
            Updates labels on all conversations in an organization.
          </Text>
          <Text color="subtitle">
            Use with caution. Will create a significant load on the system
          </Text>
        </div>
        <Button onClick={() => labels.map((l) => labelsToRemove.add(l))}>
          Select All
        </Button>
      </Flex>
      <Box ml="4" mt="4">
        {labels.map((l) => (
          <Box
            key={l}
            cursor="pointer"
            onClick={() => {
              if (labelsToRemove.has(l)) {
                labelsToRemove.delete(l)
              } else {
                labelsToRemove.add(l)
              }
            }}
          >
            <Text
              _hover={{ color: labelsToRemove.has(l) ? 'orangered' : 'grey' }}
              as="span"
              color={labelsToRemove.has(l) ? 'red' : undefined}
            >
              {l}
            </Text>
          </Box>
        ))}
      </Box>

      <Flex justifyContent="flex-end">
        <Button onClick={() => labelsToRemove.clear()}>Reset</Button>
        <Button
          colorScheme="red"
          ml="2"
          onClick={onRemove}
          isDisabled={labelsToRemove.size === 0}
        >
          Remove {labelsToRemove.size} labels
        </Button>
      </Flex>
    </div>
  )
}

export default RemoveLabels
