import request from '@capturi/request'
import { Modal } from '@capturi/ui-components'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react'
import { format } from 'date-fns/fp'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import { mutate as globalMutate } from 'swr'

import { useQueryClient } from '@tanstack/react-query'
import { IntegrationSchema } from '../../../integrations'
import {
  AsrPriority,
  ConversationOptions,
  IntegrationConfig,
} from '../../../shared/types'
import AudioChannelConfig from '../config/AudioChannelConfig'
import FieldConfig from '../config/FieldConfig'
import ScheduleConfig from '../config/ScheduleConfig'
import StartDateConfig from '../config/StartDateConfig'
import { optionsToFields } from '../utils/optionsToFields'
import UpdateApiToken from './UpdateApiToken'

type Props = {
  onClose: () => void
  organizationUid: string
  config: IntegrationConfig
  conversationOptions: ConversationOptions
  integrationOptions: Record<string, string | boolean>
  schedule: string
  integrationType: string
  integrationSchema: IntegrationSchema
  integrationKey?: string
  needsExtraRessources: boolean | undefined
}

type IntegrationResp = {
  startDate: string
  active: boolean
  organizationUid: string
}

const now = new Date()
const dateToString = format('yyyy-MM-dd')

const initialStartDate = dateToString(now)

const IntegrationModalContent: React.FC<Props> = ({
  organizationUid,
  onClose,
  integrationSchema,
  integrationType,
  integrationKey,
  ...p
}) => {
  const [integrationAuth, setIntegrationAuth] = useState<
    Record<string, string | boolean>
  >({})
  const [integrationOptions, setIntegrationOptions] = useState<
    Record<string, string | boolean>
  >(p.integrationOptions)
  const queryClient = useQueryClient()
  const [startDate, setStartDate] = useState<string>(initialStartDate)

  const [conversationOptions, setConversationOptions] =
    useState<ConversationOptions>(p.conversationOptions)

  const [needsExtraRessources, setNeedsExtraRessources] = useState<boolean>(
    !!p.needsExtraRessources,
  )
  const [schedule, setSchedule] = useState<string>(p.schedule)
  const [useTeamFromDialer, setUseTeamFromDialer] = useState<boolean>(
    p.config.useTeamFromDialer,
  )
  const [error, setError] = useState<boolean>(false)
  const [inProgress, setInProgress] = useState(false)

  const handleCreateIntegration = async (): Promise<void> => {
    try {
      setInProgress(true)

      if (integrationKey) {
        await request.put<IntegrationResp>(
          `superpowers/integration/${organizationUid}`,
          {
            json: {
              integrationType: integrationType,
              integrationKey: integrationKey,

              integrationOptions: optionsToFields(
                integrationSchema.integrationOptions,
                integrationOptions,
              ),
              conversationOptions: conversationOptions,
              config: {
                useTeamFromDialer: useTeamFromDialer,
              },
              needsExtraRessources,
              schedule,
            },
          },
        )
        queryClient.invalidateQueries({
          queryKey: ['superpowers', 'integrations'],
        })
        globalMutate(`superpowers/integration/${organizationUid}`)
      } else {
        await request.post<IntegrationResp>(
          `superpowers/integration/${organizationUid}`,
          {
            json: {
              integrationType: integrationType,
              startDate: new Date(startDate),
              integrationAuth: optionsToFields(
                integrationSchema.auth,
                integrationAuth,
              ),
              integrationOptions: optionsToFields(
                integrationSchema.integrationOptions,
                integrationOptions,
              ),
              conversationOptions: {
                audioChannel: conversationOptions.audioChannel,
                asrPriority: conversationOptions.asrPriority,
                deleteCustomer: conversationOptions.deleteCustomer,
              },
              config: {
                useTeamFromDialer: useTeamFromDialer,
              },
              schedule,
            },
          },
        )
        queryClient.invalidateQueries({
          queryKey: ['superpowers', 'integrations'],
        })
        globalMutate(`superpowers/integration/${organizationUid}`)
      }

      setInProgress(false)
      onClose()
    } finally {
      setInProgress(false)
    }
  }

  return (
    <Modal isOpen onClose={inProgress ? noop : onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{integrationType}</ModalHeader>
        {!inProgress && <ModalCloseButton />}

        <ModalBody>
          {integrationSchema.description && (
            <Text mb="4">{integrationSchema.description}</Text>
          )}
          {!integrationKey && (
            <>
              <Text as="h2" fontSize="1.5rem">
                Auth
              </Text>
              <Box mx="2">
                <FieldConfig
                  state={integrationAuth}
                  setState={setIntegrationAuth}
                  options={integrationSchema.auth}
                  inProgress={inProgress}
                />
              </Box>
            </>
          )}

          <Text as="h2" fontSize="1.5rem" mt="4">
            Options
          </Text>
          <Box mx="2">
            {!(integrationKey || integrationSchema.hideStartDate) && (
              <StartDateConfig
                setState={setStartDate}
                state={startDate}
                inProgress={inProgress}
              />
            )}
            <FieldConfig
              options={integrationSchema.integrationOptions}
              state={integrationOptions}
              setState={setIntegrationOptions}
              inProgress={inProgress}
            />

            {integrationSchema.config?.showUseTeamFromDialer && (
              <FormControl display="flex" mt="4">
                <Switch
                  id="useDialerTeam"
                  isChecked={useTeamFromDialer}
                  onChange={(e) =>
                    setUseTeamFromDialer(e.currentTarget.checked)
                  }
                  isDisabled={inProgress}
                  mr="2"
                />
                <FormLabel htmlFor="useDialerTeam">
                  Use teams from dialer
                </FormLabel>
              </FormControl>
            )}
            {!integrationSchema.audioChannel.hidden && (
              <AudioChannelConfig
                options={integrationSchema.audioChannel}
                setState={(e) =>
                  setConversationOptions((c) => ({
                    ...c,
                    audioChannel: e,
                  }))
                }
                state={conversationOptions.audioChannel}
                inProgress={inProgress}
              />
            )}
            {!integrationSchema.hideConversationOptions && (
              <>
                <FormControl mt="2">
                  <FormLabel htmlFor="asrPriority">ASR Priority</FormLabel>
                  <Select
                    id="asrPriority"
                    onChange={(e) =>
                      setConversationOptions((c) => ({
                        ...c,
                        asrPriority: e.currentTarget.value as AsrPriority,
                      }))
                    }
                    value={conversationOptions.asrPriority}
                    isDisabled={inProgress}
                  >
                    <option value={'DefaultPriority'}>Default Priority</option>
                    <option value={'LowPriority'}>Low Priority</option>
                  </Select>
                </FormControl>
                <FormControl display="flex" mt="4">
                  <Switch
                    id="deleteCustomer"
                    isChecked={conversationOptions.deleteCustomer}
                    onChange={(e) =>
                      setConversationOptions((c) => ({
                        ...c,
                        deleteCustomer: e.currentTarget.checked,
                      }))
                    }
                    isDisabled={inProgress}
                    mr="2"
                  />
                  <FormLabel htmlFor="deleteCustomer">
                    Delete Customer
                  </FormLabel>
                </FormControl>
                <FormControl display="flex" mt="4">
                  <Switch
                    id="needsExtraRessources"
                    isChecked={needsExtraRessources}
                    onChange={(e) =>
                      setNeedsExtraRessources(e.currentTarget.checked)
                    }
                    isDisabled={inProgress}
                    mr="2"
                  />
                  <FormLabel htmlFor="needsExtraRessources">
                    Needs Extra Ressources
                  </FormLabel>
                </FormControl>
              </>
            )}
            <ScheduleConfig
              setState={setSchedule}
              state={schedule}
              inProgress={inProgress}
              setError={setError}
            />
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="space-between">
          {integrationKey ? (
            <UpdateApiToken
              organizationUid={organizationUid}
              integrationKey={integrationKey}
              integrationType={integrationType}
            />
          ) : (
            <div />
          )}
          <Button
            colorScheme="primary"
            ml={3}
            onClick={handleCreateIntegration}
            isLoading={inProgress}
            isDisabled={error}
          >
            {integrationKey ? 'Update integration' : 'Add integration'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default IntegrationModalContent
